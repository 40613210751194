<template>
  <div class="page" id="call-bridge-list">
    <div class="mb-2 p-3 card header">
      <h2 class="m-0">
        {{ $t('call-bridge.calls-list.title') }}
      </h2>
      <router-link
        to="/call-bridge/calls/create"
        class="px-4 py-3 btn btn-primary"
      >
        {{ $t('call-bridge.calls-list.createCall') }}
      </router-link>
    </div>

    <div class="calls-single-table">
      <data-table
        ref="callsTables"
        :data="calls"
        :fields="translatedTableFields"
        :pagination="pagination"
        :loading="callsLoading"
        @changePage="onPageChange($event)"
      >
        <template v-slot:createdAt="{ row }">
          {{ row.createdAt }}
        </template>
        <template v-slot:status="{ row }">
          <span
            class="badge"
            :style="{
              backgroundColor: statusColor[row.status]
            }"
            >{{
              $t(`call-bridge.calls-list.table.statuses.${row.status}`)
            }}</span
          >
        </template>
        <template v-slot:endCallStatus="{ row }">
          <span
            class="badge"
            :style="{
              backgroundColor: endCallStatusColor[row.endCallStatus]
            }"
            v-if="!!row.endCallStatus"
            >{{
              $t(
                `call-bridge.calls-list.table.endCallStatuses.${row.endCallStatus}`
              )
            }}</span
          >
        </template>
        <template v-slot:firstRecipient="{ row }">
          {{ row.firstRecipient.name }}<br />
          <b>{{ row.firstRecipient.phone }}</b>
        </template>
        <template v-slot:secondRecipient="{ row }">
          {{ row.secondRecipient.name }}<br />
          <b>{{ row.secondRecipient.phone }}</b>
        </template>
        <template v-slot:details="{ row }">
          <div class="details" v-if="row.callOutcome">
            <div class="row">
              <div class="col-12 mb-4">
                <div
                  class="card p-4 gap-2 d-flex flex-column align-items-start"
                  :class="{
                    'border-success': row.callOutcome.isConnected,
                    'border-danger': !row.callOutcome.isConnected
                  }"
                >
                  <div
                    v-if="row.callOutcome.isConnected"
                    class="m-0 text-success d-flex align-items-center gap-2"
                  >
                    <span
                      class="status-icon bg-success rounded-circle d-flex justify-content-center align-items-center text-white"
                    >
                      <i class="fa fa-check"></i>
                    </span>
                    <p class="m-0">
                      <b>
                        {{
                          $t('call-bridge.calls-list.table.details.connected')
                        }}
                      </b>
                    </p>
                  </div>
                  <div
                    v-else
                    class="m-0 text-danger d-flex align-items-center gap-2"
                  >
                    <span
                      class="status-icon bg-danger rounded-circle d-flex justify-content-center align-items-center text-white"
                    >
                      <i class="fa fa-times"></i>
                    </span>
                    <p class="m-0">
                      <b>
                        {{
                          $t(
                            'call-bridge.calls-list.table.details.disconnected'
                          )
                        }}
                      </b>
                    </p>
                  </div>

                  <p class="m-0">
                    <b
                      >{{
                        $t('call-bridge.calls-list.table.details.callSummary')
                      }}: </b
                    >{{ row.callOutcome.summary[lang] }}
                  </p>
                  <p class="m-0" v-if="row.status === 'complete'">
                    <b
                      >{{
                        $t('call-bridge.calls-list.table.details.talkTime')
                      }}:
                    </b>
                    {{ row.callOutcome.talkTime }}
                  </p>
                  <p class="m-0" v-if="row.status === 'complete'">
                    <b
                      >{{
                        $t(
                          'call-bridge.calls-list.table.details.scheduleDuration'
                        )
                      }}:
                    </b>
                    {{ row.scheduleDuration }}
                  </p>
                </div>
                <div
                  class="card p-4 gap-2 d-flex flex-column align-items-start mt-4"
                  :class="{
                    'border-success': row.callOutcome.isConnected,
                    'border-danger': !row.callOutcome.isConnected
                  }"
                  v-if="row.recordingLink && row.status === 'complete'"
                >
                  <p class="m-0">
                    <b
                      >{{
                        $t('call-bridge.calls-list.table.details.recording')
                      }}:
                    </b>
                  </p>
                  <SoundPlayer :soundFile="row.recordingLink" />
                </div>
              </div>
            </div>

            <div class="row" v-if="row.status === 'complete'">
              <div class="col-12 col-lg-6">
                <div class="card p-4 gap-2 d-flex flex-column">
                  <!-- First Recipient -->
                  <h5 class="m-0">
                    {{
                      $t('call-bridge.calls-list.table.details.firstRecipient')
                    }}
                  </h5>
                  <div class="gap-2 d-flex flex-column align-items-start">
                    <p class="m-0">
                      <b
                        >{{
                          $t('call-bridge.calls-list.table.details.trials')
                        }}:
                      </b>
                      {{ row.callOutcome.firstRecipientTrials }}
                    </p>
                    <p class="m-0">
                      <b
                        >{{
                          $t(
                            'call-bridge.calls-list.table.details.lastAttemptTime'
                          )
                        }}:
                      </b>
                      {{
                        row.callOutcome.firstRecipientLastAttemptTime
                          | formatDate
                      }}
                    </p>
                    <p class="m-0">
                      <b>
                        {{
                          $t(
                            'call-bridge.calls-list.table.details.lastCallStatus'
                          )
                        }}
                        :
                      </b>
                      {{
                        $t(
                          `call-bridge.calls-list.table.recipentStatuses.${row.callOutcome.firstRecipientLastCallStatus}`
                        )
                      }}
                    </p>
                    <p class="m-0">
                      <b
                        >{{
                          $t(
                            'call-bridge.calls-list.table.details.callerNumber'
                          )
                        }}:
                      </b>
                      {{ row.callOutcome.firstCallerNumber }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div class="card p-4 gap-2 d-flex flex-column">
                  <!-- Second Recipient -->
                  <h5 class="m-0">
                    {{
                      $t('call-bridge.calls-list.table.details.secondRecipient')
                    }}
                  </h5>
                  <div class="gap-2 d-flex flex-column align-items-start">
                    <p class="m-0">
                      <b
                        >{{
                          $t('call-bridge.calls-list.table.details.trials')
                        }}:
                      </b>
                      {{ row.callOutcome.secondRecipientTrials }}
                    </p>
                    <p class="m-0">
                      <b
                        >{{
                          $t(
                            'call-bridge.calls-list.table.details.lastAttemptTime'
                          )
                        }}:
                      </b>
                      {{
                        row.callOutcome.secondRecipientLastAttemptTime
                          | formatDate
                      }}
                    </p>
                    <p class="m-0">
                      <b
                        >{{
                          $t(
                            'call-bridge.calls-list.table.details.lastCallStatus'
                          )
                        }}:
                      </b>
                      {{
                        $t(
                          `call-bridge.calls-list.table.recipentStatuses.${row.callOutcome.secondRecipientLastCallStatus}`
                        )
                      }}
                    </p>
                    <p class="m-0">
                      <b
                        >{{
                          $t(
                            'call-bridge.calls-list.table.details.callerNumber'
                          )
                        }}:
                      </b>
                      {{ row.callOutcome.secondCallerNumber }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions="{ row }">
          <div class="d-flex align-items-center gap-2">
            <button
              class="btn btn-primary shadow-none px-3 py-2"
              @click="toggleDetails(row.id)"
              :disabled="['pending', 'in-progress'].includes(row.status)"
            >
              <i class="fa fa-eye"></i>
            </button>
            <button
              class="btn btn-danger px-3 py-2"
              @click="deleteCall(row)"
              v-if="row.status === 'pending'"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CallBridgesService from '../../../services/callBridge.service'
import DataTable from '../../../elements/Table.vue'
import moment from 'moment'
import SoundPlayer from '../../../elements/SoundPlayer.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      fields: [
        {
          accessor: 'firstRecipient',
          header: 'call-bridge.calls-list.table.columns.firstRecipient'
        },
        {
          accessor: 'secondRecipient',
          header: 'call-bridge.calls-list.table.columns.secondRecipient'
        },
        {
          accessor: 'endCallStatus',
          header: 'call-bridge.calls-list.table.columns.endCallStatus'
        },
        {
          accessor: 'status',
          header: 'call-bridge.calls-list.table.columns.status'
        },
        {
          accessor: 'scheduleDateTime',
          header: 'call-bridge.calls-list.table.columns.scheduleDateTime'
        },
        {
          accessor: 'timezone',
          header: 'call-bridge.calls-list.table.columns.timezone'
        },
        {
          accessor: 'actions',
          header: 'call-bridge.calls-list.table.columns.actions'
        }
      ],
      calls: [],
      callsLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      }
    }
  },
  components: { DataTable, SoundPlayer },
  mounted() {
    this.getCalls()
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.getCalls(page)
    },
    async getCalls() {
      this.callsLoading = true
      const res = await CallBridgesService.listAllBridgeCalls(
        {},
        this.pagination.page
      )
      const { calls, ...pagination } = res
      this.calls = calls
      this.pagination.totalPages = pagination.totalPages
      this.callsLoading = false
    },
    toggleDetails(id) {
      this.$refs.callsTables.toggleDetails(id)
    },
    async deleteCall(call) {
      try {
        if (call.status !== 'pending') {
          this.toast(
            $t('call-bridge.calls-list.table.toasts.deletePendingCallError'),
            {
              type: 'error'
            }
          )
          return
        }
        const res = await CallBridgesService.deleteBridgeCall(call.id)
        this.toast(res.message)
        this.getCalls()
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, { type: 'error' })
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    lang() {
      return this.$i18n.locale()
    },
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    },
    statusColor() {
      return {
        pending: '#6c757d',
        processing: '#ffc107',
        'in-progress': '#28a745',
        complete: '#007bff',
        deleted: '#dc3545'
      }
    },
    endCallStatusColor() {
      return {
        Completed: '#28a745',
        'Call Failed': '#ff004f',
        Deleted: '#dc3545'
      }
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return ''
      const date = new Date(value)
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  watch: {
    currentTenant() {
      this.getCalls()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.gap-2 {
  gap: 0.5rem;
}
.status-icon {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
